<template>
  <div>
    <b-card title="Administracion - Productos">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6" class="input-search">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                v-if="$hascan('producto-registrar')"
                @click="$refs.modalFormRef.abrir('registrar', [])"
              >
                <feather-icon icon="SaveIcon" class="mr-50" />
                <span class="align-middle">NUEVO</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('producto-editar')"
                @click="$refs.modalFormRef.abrir('actualizar', item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-lg-block">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span class="align-middle">EDITAR</span>
                </span>
                <feather-icon icon="Edit2Icon" class="d-lg-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('producto-eliminar')"
                @click="confirmarEliminar(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-lg-block">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">ELIMINAR</span>
                </span>
                <feather-icon icon="XIcon" class="d-lg-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('producto-ver') && mixing.printAppActivo"
                @click="imprimirCodigo(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-lg-block">
                  <feather-icon icon="MaximizeIcon" class="mr-50" />
                  <span class="align-middle">IMPRIMIR CÓDIGO</span>
                </span>
                <feather-icon icon="MaximizeIcon" class="d-lg-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('producto-ver') && mixing.printTicket"
                @click="$refs.modalImprimirEtiquetas.abrir(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-lg-block">
                  <feather-icon icon="MaximizeIcon" class="mr-50" />
                  <span class="align-middle">IMPRIMIR CÓDIGO</span>
                </span>
                <feather-icon icon="MaximizeIcon" class="d-lg-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-lg-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-lg-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <b-row style="justify-content: flex-end">
              <b-col cols="12" md="6" lg="4">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          :realizarFacturacion="mixing.realizarFacturacion"
          :giroComercialId="mixing.giroComercialId"
          :items="items"
          :loading="loading"
          @selectItemArticulo="selectItemArticulo"
        />
      </b-card-text>
    </b-card>
    <ModalFormProducto
      ref="modalFormRef"
      :item="item"
      @actualizarArticulo="actualizarArticulo"
    />
    <ModalFormProductoAjuste
      ref="modalFormAjusteRef"
      :item="item"
      @actualizarArticulo="actualizarArticulo"
    />

    <ModalImprimitEtiquetas ref="modalImprimirEtiquetas" />
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import ModalFormProducto from "../components/ModalFormProducto.vue";
import ModalFormProductoAjuste from "../components/ModalFormProductoAjuste.vue";
import ProductoServices from "../services/index";
import ModalImprimitEtiquetas from "../components/ModalImprimirEtiquetas.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    Listado,
    BRow,
    BCol,
    BPagination,
    ModalFormProducto,
    ModalFormProductoAjuste,
    ModalImprimitEtiquetas,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      items: [],
      item: [],
      buttonDisabled: true,
      searchQuery: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,
    };
  },
  created() {},
  methods: {
    async listar() {
      this.loading = true;
      try {
        const response = await ProductoServices.get(this.mixing.sede_id);
        this.items = response.data;
        this.item = [];
        this.buttonDisabled = true;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async eliminar(id) {
      try {
        const response = await ProductoServices.destroy(id);
        this.$bvToast.toast("Se elimino correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });
        this.listar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // Emit modal articulo
    actualizarArticulo() {
      this.listar();
      this.item = [];
      this.buttonDisabled = true;
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.listar();
    },
    selectItemArticulo(item, bool) {
      this.item = item;
      this.buttonDisabled = bool;
    },
    updateSearchQuery(val) {
      this.$refs.listadoRef.updateSearchQuery(val);
    },
    confirmarEliminar(item) {
      this.$bvModal
        .msgBoxConfirm("Confirme que desea eliminar : " + item.descripcion, {
          title: "ELIMINAR ARTICULO ?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si, Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-dark",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.eliminar(item.id);
          }
        });
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    async imprimirCodigo(item) {
      const codigo = item.codigo;
      const url = this.mixing.printAppUrl;
      try {
        await axios.get(`${url}?codigo=${codigo}&cantidad=1`);

        this.$bvToast.toast("Código generado correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });
      } catch (error) {
        this.$bvToast.toast("Error al generar código.", {
          title: "OK!",
          variant: "danger",
          solid: false,
        });
      }
    },
  },
  mounted() {
    this.listar();
  },
};
</script>

<style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
