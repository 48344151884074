<template>
  <b-modal
    v-model="mostrado"
    title="Imprimir Etiquetas"
    no-close-on-backdrop
    :ok-disabled="enviado"
    :cancel-disabled="enviado"
    no-close-on-esc
    ok-title="Imprimir"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cancelar"
    variant="dark"
    button-size="sm"
    size="md"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="enviado"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <b-row>
      <b-col class="mb-1" cols="12">
        <b-form-group
          label="IMPRESORA"
          label-for="impresora"
          label-cols="12"
          label-align-md="4"
        >
          <b-form-select
            id="impresora"
            v-model="impresoraActual"
            :options="impresoras"
            size="sm"
            value-field="value"
            text-field="name"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>
                -- Seleccione una impresora --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <template v-if="impresoras.length > 0">
        <b-col cols="12" md="4">
          <div class="mb-1">
            <feather-icon
              icon="PrinterIcon"
              size="24"
              class="mr-50"
              :class="[impresoraEstaEncendida ? 'text-success' : 'text-danger']"
            />
            <span
              :class="[impresoraEstaEncendida ? 'text-success' : 'text-danger']"
              >{{ impresoraEstaEncendida ? "ENCENDIDA" : "APAGADA" }}</span
            >
          </div>
        </b-col>

        <b-col class="mb-3" cols="12" md="8">
          <b-button
            v-if="!impresoraEstaEncendida"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="dark"
            size="sm"
            block
            @click="encenderImpresora"
          >
            <span class="d-none d-lg-block">
              <feather-icon icon="PrinterIcon" class="mr-50" />
              <span class="align-middle">ENCENDER IMPRESORA</span>
            </span>
            <feather-icon icon="PrinterIcon" class="d-lg-none" size="14" />
          </b-button>

          <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="dark"
            size="sm"
            block
            @click="apagarImpresora"
          >
            <span class="d-none d-lg-block">
              <feather-icon icon="PrinterIcon" class="mr-50" />
              <span class="align-middle">APAGAR IMPRESORA</span>
            </span>
            <feather-icon icon="PrinterIcon" class="d-lg-none" size="14" />
          </b-button>
        </b-col>
      </template>

      <template v-if="item">
        <b-col class="mb-1" cols="12" md="4">
          <strong>PRODUCTO: </strong>
        </b-col>
        <b-col class="mb-1" cols="12" md="8">
          <span>{{ item.descripcion }}</span>
        </b-col>
      </template>

      <b-col class="mb-1" cols="12">
        <validation-observer ref="validationObserver">
          <b-form>
            <b-form-group
              label="* CANTIDAD ETIQUETAS"
              label-for="cantidadEtiquetas"
              label-cols="12"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Cantidad Etiquetas"
                rules="required"
              >
                <b-form-input
                  id="cantidadEtiquetas"
                  type="number"
                  disabled
                  v-model="cantidadEtiquetas"
                  size="sm"
                  min="1"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col>
        <div class="vista-previa mt-2" ref="vistaPrevia"></div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BFormSelectOption,
  BFormSpinbutton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { DTPWeb, LPA_JobNames } from "dtpweb";

export default {
  name: "ModalImprimirEtiquetas",
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    BFormSelectOption,
    BFormSpinbutton,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      mostrado: false,
      item: null,
      enviado: false,
      cantidadEtiquetas: 1,
      /**
       * @type {DTPWeb | undefined}
       */
      apiImpresora: undefined,
      impresoras: [],
      impresoraActual: {},
      impresoraEstaEncendida: false,
      tipoBrechaActual: 255,
      velocidadActual: 255,
      obscuridadActual: 255,
      configuracionesImpresion: {
        ancho: 50,
        alto: 25,
        horientacionActual: 0,
        nombreProcesoActual: LPA_JobNames.Preview,
        margenX: 3,
        margenY: 3,
      },
    };
  },
  mounted() {
    DTPWeb.getInstance().checkPlugin((api) => {
      this.apiImpresora = api;
      if (api) {
        this.actualizarListaImpresoras();
      } else {
        this.printers[0].name = "No se detecta el plug-in de impresión";
      }
    });
  },
  watch: {
    impresoraActual() {
      this.actualizarParametrosDeImpresion();
    },
    cantidadEtiquetas() {
      this.dibujarCodigoBarras();
    },
  },
  methods: {
    validar() {
      this.$refs.validationObserver.validate().then((success) => {
        if (success) {
          if (!this.enviado) {
            this.imprimirCodigoBarras();
          }
        }
      });
    },
    async abrir(item = []) {
      this.mostrado = true;
      this.item = item;
      this.comprobarImpresoraEstaEncendida();
      this.dibujarCodigoBarras();
    },
    cerrarModal() {
      this.mostrado = false;
    },
    actualizarArticulo() {
      this.mostrado = false;
      this.$emit("actualizarArticulo");
      this.enviado = false;
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    /**
     * Actualizar la lista de impresoras.
     */
    actualizarListaImpresoras() {
      if (!this.apiImpresora) return;
      //
      this.apiImpresora.getPrinters().then((values) => {
        // Vaciar la lista de impresoras antiguas
        this.impresoras.splice(0);
        //
        if (values?.length > 0) {
          for (const item of values) {
            const title =
              item.type === 1 ? item.name : `${item.name}@${item.ip}`;
            this.impresoras.push({
              name: title,
              value: item,
            });
          }
          //
          this.impresoraActual = this.impresoras[0].value;
          // Actualizar parámetros de impresión
          this.actualizarParametrosDeImpresion();
        }
      });
    },
    /**
     * Obtener y actualizar los parámetros de impresión.
     */
    async actualizarParametrosDeImpresion() {
      if (!this.apiImpresora) return;

      if (!(await this.apiImpresora.openPrinter(this.impresoraActual))) return;

      this.tipoBrechaActual = await this.apiImpresora.getGapType();
      this.obscuridadActual = await this.apiImpresora.getPrintDarkness();
      this.velocidadActual = await this.apiImpresora.getPrintSpeed();

      this.apiImpresora.closePrinter();
    },
    async comprobarImpresoraEstaEncendida() {
      this.impresoraEstaEncendida = await this.apiImpresora?.isPrinterOpened();
    },
    async encenderImpresora() {
      const impresora = this.impresoras[0].value;
      const response = await this.apiImpresora?.openPrinter(impresora);

      if (response) {
        this.$bvToast.toast("La impresora se ha ENCENDIDO correctamente.", {
          title: "EXITO!",
          variant: "success",
          solid: false,
          position: "bottom-center",
        });
      } else {
        this.$bvToast.toast("No se pudo ENCENDER la impresora.", {
          title: "ERROR!",
          variant: "danger",
          solid: false,
          position: "bottom-center",
        });
      }

      this.comprobarImpresoraEstaEncendida();
    },
    async apagarImpresora() {
      const response = await this.apiImpresora.closePrinter();

      if (response) {
        this.$bvToast.toast("La impresora se ha a APAGADO correctamente.", {
          title: "EXITO!",
          variant: "success",
          solid: false,
          position: "bottom-center",
        });
      } else {
        this.$bvToast.toast("No se pudo APAGAR la impresora.", {
          title: "ERROR!",
          variant: "danger",
          solid: false,
          position: "bottom-center",
        });
      }

      this.comprobarImpresoraEstaEncendida();
    },
    esImpresion() {
      return (
        this.currJobName !== LPA_JobNames.Preview &&
        this.currJobName !== LPA_JobNames.Transparent
      );
    },
    async vistaPreviaProceso() {
      this.$refs["vistaPrevia"].innerHTML = "";

      const info = await this.apiImpresora.getPageInfo();

      if (info) {
        const pages = [];
        for (let i = 0; i < info.pages; i++) {
          const page = await this.apiImpresora.getPageImage({ page: i });
          pages.push(page.data);
        }

        this.vistaPreviaPaginas(pages);
      }
    },
    vistaPreviaPaginas(pages) {
      if (pages && pages.length > 0) {
        for (const page of pages) {
          this.agregarVistaPrevia(page);
        }
      }
    },
    agregarVistaPrevia(data) {
      if (!data) return;

      const previewGroup = this.$refs["vistaPrevia"];
      const img = document.createElement("img");
      img.classList.add("img-previa");
      img.src = data;
      previewGroup.appendChild(img);
      previewGroup.appendChild(document.createElement("br"));
    },
    async dibujarCodigoBarras() {
      const impresoraConectada = await this.apiImpresora?.openPrinter(
        this.impresoraActual
      );

      if (this.esImpresion() && !impresoraConectada) {
        return;
      }

      const ancho = this.configuracionesImpresion.ancho;
      const alto = this.configuracionesImpresion.alto;
      const orientacion = this.configuracionesImpresion.horientacionActual;
      const proceso = this.configuracionesImpresion.nombreProcesoActual;
      const texto = this.item.codigo;
      const margenX = this.configuracionesImpresion.margenX;
      const margenY = this.configuracionesImpresion.margenY;
      const cantidadEtiquetas = parseInt(this.cantidadEtiquetas);
      const iteraciones = new Array(cantidadEtiquetas).fill(1);

      await Promise.all(
        iteraciones.map(async () => {
          await this.apiImpresora.startJob({
            width: ancho,
            height: alto,
            orientation: orientacion,
            jobName: proceso,
          });

          // CORRECCIONES DE WILL
          await this.apiImpresora.draw1DBarcode({
            text: texto,
            x: margenX,
            y: margenY,
            width: ancho - margenX * 3,
            height: alto - margenY * 2,
            textHeight: 7, // ANTES ERA 5
            barcodeType: 28,
          });

          await this.apiImpresora.drawText({
            text: texto,
            x: margenX,
            y: margenY + alto - 13, // ANTES ERA 10
            horizontalAlignment: 1,
            width: ancho - margenX * 2,
            fontName: "宋体",
          });

          // ESTO ES NUEVO
          await this.apiImpresora.drawText({
            text: `Bs. ${this.item.precio_unitario}`,
            x: margenX,
            y: margenY + alto - 10,
            horizontalAlignment: 1,
            width: ancho - margenX * 2,
            fontName: "宋体",
          });

          await this.apiImpresora.commitJob();

          // Si el tipo de tarea actual es Vista previa, se muestra el efecto de vista previa.
          await this.vistaPreviaProceso();
        })
      );
    },
    async imprimirCodigoBarras() {
      /**
       * @type {HTMLElement}
       */
      const datas = this.$refs["vistaPrevia"];

      if (datas && datas.childNodes) {
        const childNodes = datas.childNodes;
        for (let i = 0; i < childNodes.length; i++) {
          if (childNodes[i].src) {
            await this.apiImpresora.printImage({
              data: childNodes[i].src,
              printerName: this.impresoraActual?.name,
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.vista-previa {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.vista-previa::-webkit-scrollbar {
  width: 0.5rem;
}

.vista-previa::-webkit-scrollbar-track {
  background-color: #2a3942;
}

.vista-previa::-webkit-scrollbar-thumb {
  background-color: #4c5960;
}

.img-previa {
  display: block;
  width: 80%;
  margin: 0 auto;
}
</style>