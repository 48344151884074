<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table mb-1"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="items"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @selection-changed="onSelectionChanged"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                items.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : items.length
              }}
              de {{ items.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import {
  BCard,
  BCardText,
  BOverlay,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    BOverlay,
    BPagination,
    BRow,
    BCol,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    realizarFacturacion: {
      type: Boolean,
      default: false,
    },
    giroComercialId: {
      type: Number,
      required: true,
    },
  },
  // props: ["items", "loading", "realizarFacturacion","giroComercialId"],
  data() {
    return {
      // grid
      gridOptions: {
        suppressPropertyNamesCheck: true,
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        filter: true,
        floatingFilter: true,
        //editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 280,
          maxWidth: 280,
        },
        {
          headerName: "PRODUCTO / SERVICIO",
          field: "descripcion",
          autoHeight: true,
          wrapText: true,
          filter: false,
          minWidth: 350,
        },
        {
          headerName: "PRINCIPIO ACTIVO",
          field: "principio_activo",
          autoHeight: true,
          wrapText: true,
          filter: false,
          minWidth: 350,
          hide: this.giroComercialId != 1,
        },
        {
          headerName: "ACT. ECO.",
          field: "codigo_actividad",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
          hide: !this.realizarFacturacion,
        },
        {
          headerName: "PRECIO",
          field: "precio_unitario",
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "CANTIDAD",
          cellRenderer: function (param) {
            if (param.data.es_servicio == 1) {
              return '<span class="badge badge-warning">SERV.</span>';
            } else {
              const stock = parseFloat(param.data.stock).toFixed(2);
              return stock > 0
                ? stock
                : `<span class="text-danger">${stock}</span>`;
            }
          },
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "CATEGORIA",
          field: "categoria.descripcion",
          filter: false,
          minWidth: 250,
          maxWidth: 250,
        },
        {
          headerName: "MARCA-LINEA",
          field: "marca.descripcion",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "AREA-UBICACION",
          field: "ubicacion.descripcion",
          filter: false,
          minWidth: 140,
          maxWidth: 140,
        },
        {
          headerName: "UNIDAD",
          field: "unidad.descripcion",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "PROD. SIN",
          field: "codigo_sin",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
          hide: !this.realizarFacturacion,
        },
        {
          headerName: "ACT. ECO.",
          field: "codigo_actividad",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
          hide: !this.realizarFacturacion,
        },
        {
          headerName: "IMAGEN",
          cellRenderer: function (param) {
            let imagen = "";
            if (param.data.imagen) {
              imagen = `<div class="boxA box1">
                          <img src="${param.data.imagen}"/>
                      </div>`;
            }
            return imagen;
          },
          autoHeight: true,
          filter: false,
          minWidth: 100,
          maxWidth: 100,
          hide: true,
        },
        {
          headerName: "ESTADO",
          filter: false,
          cellRenderer: function (param) {
            return param.data.esta_activo == "1"
              ? "ACTIVO"
              : "<span style='color:#ea5455;'>INACTIVO</span>";
          },
          minWidth: 100,
          maxWidth: 100,
        },
      ],
      searchQuery: "",
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created() {},
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemArticulo", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemArticulo", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham-dark .row-red {
  color: #ea5455 !important;
}
.boxA {
  height: 5rem;
}
.boxA img {
  width: 100%;
  height: 100%;
}
.box1 img {
  object-fit: cover;
  // object-fit: contain;
}
</style>
