<template>
  <b-modal
    id="modal-from-articulo-ajuste"
    v-model="modalAjuste"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="dark"
    cancel-variant="outline-dark"
    cancel-title="Cerrar"
    variant="dark"
    button-size="sm"
    size="lg"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <!-- size="xl" -->
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12" md="9" lg="10">
            <b-form-group label="Nombre del Articulo ">
              <b-form-input :value="form.descripcion" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" lg="2">
            <b-form-group label="Cantidad Actual">
              <b-form-input :value="form.cantidad" disabled />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" md="9" lg="10">
            <b-form-group label="Observacion">
              <b-form-input />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" lg="2">
            <b-form-group label="Cantidad">
              <b-form-input />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import axiosIns from "@/libs/axios";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BTabs,
  BTab,
  BFormSelect,
  BInputGroup,
  BFormFile,
  BInputGroupAppend,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BImg,
  BFormCheckbox,
  BButtonGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BTabs,
    BTab,
    BFormSelect,
    vSelect,
    BInputGroup,
    BFormFile,
    BOverlay,
    BInputGroupAppend,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BImg,
    BFormCheckbox,
    BButtonGroup,
  },
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
      },
      tipoAjuste: "",
      submitted: false,
      errores: [],
      required,
      modalAjuste: false,
    };
  },
  created: function () {
    window.addEventListener("keyup", this.onkey);
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  computed: {
    tituloModal() {
      return this.tipoAjuste == "AJUSTE POSITIVO"
        ? "AJUSTE POSITIVO"
        : "AJUSTE NEGATIVO";
    },
  },
  watch: {},
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.tipoAjuste == "AJUSTE POSITIVO") {
              this.ajustePositivo();
            } else {
              this.ajusteNegativo();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      this.tipoAjuste = accion;
      switch (accion) {
        case "AJUSTE POSITIVO": {
          this.form = data;
          this.modalAjuste = true;
          break;
        }
        case "AJUSTE NEGATIVO": {
          this.form = data;
          this.modalAjuste = true;
          break;
        }
      }
      this.errores = [];
    },
    cerrarModal() {
      this.form.id = null;
      this.modalAjuste = false;
    },
    actualizarArticulo(item) {
      this.form.id = null;
      this.modalAjuste = false;
      this.$emit("actualizarArticulo", item);
      this.submitted = false;
    },
    ajustePositivo() {
      this.modalAjuste = false;
      this.submitted = false;
      // axiosIns
      //   .post("articulos", this.formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((res) => {
      //     this.actualizarArticulo(res.data.articulo);
      //     this.errores = [];
      //   })
      //   .catch((err) => {
      //     this.submitted = false;
      //     this.errores = err.response.data.errors;
      //   });
    },
    ajusteNegativo() {
      this.modalAjuste = false;
      this.submitted = false;
      // axiosIns
      //   .post("articulos/" + this.form.id, this.formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((res) => {
      //     this.actualizarArticulo(res.data.articulo);
      //     this.errores = [];
      //   })
      //   .catch((err) => {
      //     this.submitted = false;
      //     this.errores = err.response.data.errors;
      //   });
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.vs__deselect {
  fill: rgb(255 255 255);
}
.boxs {
  height: 14rem;
}
.boxs img {
  width: 100%;
  height: 100%;
}
.boxs1 img {
  object-fit: contain;
}
#modal-from-articulo-ajuste {
  .modal-xl {
    margin-left: 20%;
    margin-right: 20%;
  }
}
</style>